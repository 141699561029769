import * as React from 'react';
import BioAppBar from './components/BioAppBar'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Analysis from "./pages/Analysis";
import Users from "./pages/Users";

import FirebaseAPI from "./data/FirebaseAPI";
import {useEffect, useState} from "react";
import LoginCard from "./components/LoginCard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const firebaseAPI = new FirebaseAPI();

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebaseAPI.getAuth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      console.log('user', user)
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <React.Fragment>
        <LoginCard></LoginCard>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <BioAppBar></BioAppBar>
        <Box id="content" sx={{height: '100%', width: '100%', flex: 1, display: "flex", flexDirection: "column"}}>
          <Routes>
            <Route path="/">
              <Route index element={<Analysis />} />
              <Route path="analysis" element={<Analysis />} />
              <Route path="users" element={<Users />} />
            </Route>
          </Routes>
        </Box>
        <Box sx={{width: '100%'}}>
          <Typography sx={{textAlign: 'center'}}gutterBottom variant="p" component="div">
            © Nicolas Broeking, 2024
          </Typography>
        </Box>
      </BrowserRouter>
    </React.Fragment>


  );
}
export default App;
