import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FirebaseAPI from "../data/FirebaseAPI";
import Modal from "@mui/material/Modal";

function LoginCard(props) {
  return (
    <Modal
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      open={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{ midWidth: '300px', maxWidth: '500px', }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              You must be logged in to view use this app.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => new FirebaseAPI().login().catch(err => console.error(err))}>
            Login / Signup with Google Account
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

export default LoginCard;
