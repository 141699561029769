import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

function getAvatar(user) {
  return Array.from(user.lastName)[0];
}

function UserCard(props) {
  const user = props.user;
  const id = user.email;
  return (
    <Grid item xs={3} key={id}>
      <Card sx={{flexGrow: 1,}}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              {getAvatar(user)}
            </Avatar>
          }
          title={user.firstName + " " + user.lastName}
        />
        <CardContent>
          <Typography variant="body1" color="textPrimary" component="p">
            Name: {user.firstName + " " + user.lastName}
          </Typography>
          <Divider />
          <Typography variant="body1" color="textSecondary" component="p">
            Sex: {user.sex}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Symptoms: {user.symptoms}
          </Typography>
        </CardContent>
      </Card>
    </Grid>)
}

export default UserCard;
