import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState} from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FirebaseAPI from "../data/FirebaseAPI";
function AddUserForm(props) {

  const close = () => {
    if (props.close) {
      props.cancel()
    }
  }

  const [firstName, setFirstName]= useState("")
  const [lastName, setLastName] = useState("")
  const [sex, setSex] = useState("")
  const [symptoms, setSymptoms] = useState("")

  const save = () => {
    if (props.save) {
      props.save()
    }

    const newUser = {
      id: firstName + "-" + lastName,
      email: firstName + "." + lastName + "@fakeemail.com",
      firstName: firstName,
      lastName: lastName,
      sex: sex,
      symptoms: symptoms
    }

    new FirebaseAPI().addUser(newUser)
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <Card sx={{ midWidth: '300px', maxWidth: '500px' }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Enter User Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                required
                id="firstName"
                label="First Name"
                placeholder="First Name"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                required
                id="lastName"
                label="Last Name"
                placeholder="Last Name"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value)}} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                required
                id="sex"
                label="Sex"
                value={sex}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSex(event.target.value)}} />
            </Grid>
          </Grid>
          <TextField
            id="symptoms"
            label="Symptoms"
            multiline
            maxRows={4}
            variant="filled"
            placeholder="Enter Symptoms (4 Rows Max)"
            value={symptoms}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSymptoms(event.target.value)}} />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={save}>
          Add User
        </Button>
        <Button size="small" color="primary" onClick={close}>
          Cancel
        </Button>
      </CardActions>
    </Card>
  );
}

export default AddUserForm;
