import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddUserCard from "../components/AddUserCard";
import Grid from "@mui/material/Grid";
import FirebaseAPI from "../data/FirebaseAPI";
import UserCard from "../components/UserCard";

const firebaseAPI = new FirebaseAPI();

function renderUser(user) {
  return (<UserCard user={user}/>)
}

function Users(props) {
  const style = {};

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState({})


  const handleClose = function() {
    console.log('closed')
  }

  useEffect(() => {
    const unsubscribe = firebaseAPI.subscribeToUsers(snapshot => {
        console.log(snapshot.val())
        setUsers(snapshot.val())
      });

    return () => unsubscribe()
  }, [])

  return (
    <React.Fragment>
      <Fab sx={fabStyle} color="primary" aria-label="add" onClick={() => setOpen(true)}>
        <AddIcon />
      </Fab>

      <Modal
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddUserCard close={() => {setOpen(false)}} save={() => {setOpen(false)}}/>
        </Box>
      </Modal>

      <Grid sx={{padding: '5px'}} container spacing={3}>
        {Object.values(users)
          .map((user) => renderUser(user))}
      </Grid>

    </React.Fragment>
  );
}
export default Users;
