import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState} from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FirebaseAPI from "../data/FirebaseAPI";
import Box from "@mui/material/Box";
import UserCard from "../components/UserCard";

const firebaseAPI = new FirebaseAPI();

function renderUser(user) {
  return (<UserCard user={user}/>)
}

function getUserResults(idResponse, allUserMap) {

  const result = {};
  idResponse.forEach(id => {
    if (allUserMap.hasOwnProperty(id)) {
      result[id] = allUserMap[id];
    }
  })

  return result;
}

function Analysis(props) {

  const [prompt, setPrompt]= useState("");
  const [users, setUsers] = useState({});
  const [reason, setReason] = useState("");

  const save = () => {
    setReason("processing");
    setUsers({});

    console.log('submit prompt', prompt);

    return firebaseAPI.getAllUsers()
      .then(users => {
        const input = {
          prompt: prompt,
          users: users.val()
        };
        return firebaseAPI.computeUsersFunc(input)
          .then(result => {
            console.log("getAll", users.val())
            console.log('response', result)
            const  matchedUsers = getUserResults(result.data.matchedIds, users.val());
            return {users: matchedUsers, reason: result.data.reason}
          })
      })
      .then(res => {
        const users = res.users;
        const reason = res.reason;
        console.log('res', res)
        if (users) {
          setUsers(users)
        }

        if (reason) {
          setReason(reason)
        } else {
          setReason('Completed with Unknown Reason')
        }
      })
      .catch(err => {
        console.error("failed to compute", err)
        setReason("request failed")
      })
  }

  return (
    <Box id="analysis" sx={{width: '80vp', flex: 1, paddingRight: '5vw', paddingLeft: '5vw', paddingTop:'5vh', paddingBottom: '5vh', display: 'flex', flexDirection: 'column'}}>
      <Card sx={{ midWidth: '80vp'}}>
        <CardActionArea sx={{ width: '100%'}}>
          <CardContent sx={{ width: '100%'}}>
            <Typography gutterBottom variant="h5" component="div">
              Enter information about your clinical trial
            </Typography>
            <TextField
              id="trial"
              label="Clinical Trial"
              sx={{width:'100%'}}
              multiline
              minRows={10}
              variant="filled"
              placeholder="Enter information about your clinical trial"
              value={prompt}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPrompt(event.target.value)}} />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={save}>
            Submit
          </Button>
        </CardActions>
      </Card>

      <Box id="results" sx={{flex: 1, padding: '10px'}}>
        <Grid sx={{padding: '5px'}} container spacing={3}>
          {Object.values(users)
            .map((user) => renderUser(user))}
        </Grid>
        <Box sx={{height:"5vh"}}></Box>
        <Typography gutterBottom variant="h5" component="div">
          Reasoning
        </Typography>
        <Typography gutterBottom variant="p" component="div">
          {reason}
        </Typography>
      </Box>
    </Box>


  );
}

export default Analysis;
