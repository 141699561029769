// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, set, onValue, get } from "firebase/database";
import { getAuth, signOut, signInWithRedirect, setPersistence, GoogleAuthProvider, browserSessionPersistence} from "firebase/auth";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAorONLmi7LEgOna5AtkWKClrf16cNN_As",
  authDomain: "bioai-b00a1.firebaseapp.com",
  projectId: "bioai-b00a1",
  storageBucket: "bioai-b00a1.appspot.com",
  messagingSenderId: "353672674320",
  appId: "1:353672674320:web:67003417d64f4be1fd9e95",
  measurementId: "G-5N0SHQP2TW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const database = getDatabase(app)
const auth = getAuth(app);

const functions = getFunctions(app);
console.log('firebase initialized')

class FirebaseAPI {
  constructor() {
    this.app = app;
    this.analytics = analytics;
    this.database = database;
    this.auth = auth;
    this.functions = functions;
    this.computeUsersFunc = httpsCallable(functions, 'runAnalysisV1');
  }

  login() {
    console.log("attempting to log in")
    return setPersistence( this.auth, browserSessionPersistence)
      .then(() => {
        return signInWithRedirect(this.auth, new GoogleAuthProvider())
      })
      .catch((err) => console.warn("failed to login", err));
  }

  logout() {
    return signOut(this.auth)
      .then(() => console.info("signed out"))
      .catch(err => console.error("failed to sign out"))
  }

  getUserRef() {
    return ref(this.database, "users")
  }

  addUser(user) {
    return Promise.resolve()
      .then(() => {
        console.log('saving user', user)
      })
      .then(() => {
        if (user.email == null) {
          return Promise.reject("email missing")
        }
        return set(ref(this.database, "users/" + user.id), user)
      })
  }

  getAuth() {
    return this.auth;
  }

  subscribeToUsers(callback) {
    return onValue(this.getUserRef(), callback)
  }

  getAllUsers() {
    return get(this.getUserRef())
  }

  computeUsers(queryText) {
    this.computeUsersFunc()
      .then(response => {
        console.log('response', response)
      })
      .catch(err => {
        console.log('compute failed', err)
      })
  }

  getLoginConfig() {
    // Configure FirebaseUI.
    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
      },
    };
    return uiConfig
  }
}

export default FirebaseAPI;
